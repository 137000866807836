<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showCreateButton
    showEditButton
    showDeleteButton
    @create="onCreate('LauncherPlatformCreate')"
    @edit="(id) => onEdit('LauncherPlatformEdit', id)"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'LauncherPlatforms',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'LauncherPlatforms',
      tableCaption: 'Настройка платформ',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Настройка платформ',
        },
      ],
      tableHeaders: [
        { text: 'Имя', alias: 'name', order: 'name' },
        { text: 'Приложение', alias: 'application', order: 'application' },
        {
          text: 'Аргументы',
          alias: 'argumentTemplate',
          order: 'argumentTemplate',
        },
        { text: 'Конфигуратор', alias: 'isConfig', order: 'isConfig' },
        { text: '', alias: 'actions', order: 'actions' },
      ],
    };
  },
};
</script>
